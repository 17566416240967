<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-row class="mt-2">
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">CPF</label>
              <v-text-field
                v-model="dependent.cpf"
                v-mask="'###.###.###-##'"
                outlined
                color="textPrimary"
                :rules="dependent.cpf ? [rule.cpf] : []"
                validate-on-blur
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Nome</label>
              <v-text-field
                v-model="dependent.name"
                required
                outlined
                color="textPrimary"
                :disabled="isDetail"
                :rules="dependent.name ? [rule.name, rule.validateIsAlphanumeric] : []"
                @blur="dependent.name ? dependent.name = formatter.formatToTitleCase(dependent.name) : null"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Data de nascimento</label>
              <v-text-field
                v-model="dependent.birthDate"
                color="textPrimary"
                outlined
                :disabled="isDetail"
                @input="observerDependents()"
                append-icon="fas fa-calendar-alt"
                placeholder="DD/MM/YYYY"
                v-mask="'##/##/####'"
                :rules="dependent.birthDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                validate-on-blur
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">UF de Nascimento</label>
              <v-autocomplete
                v-model="dependent.stateBirth"
                :items="states"
                item-text="label"
                item-value="label"
                outlined
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Gênero</label>
              <v-autocomplete
                v-model="dependent.gender"
                :items="propsGenders"
                item-text="description"
                item-value="id"
                outlined
                color="textPrimary"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Nome da Mãe</label>
              <v-text-field
                v-model="dependent.motherName"
                outlined
                color="textPrimary"
                :disabled="isDetail"
                :rules="dependent.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
                validate-on-blur
                @blur="dependent.motherName ? dependent.motherName = formatter.formatToTitleCase(dependent.motherName) : null"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Grau de Parentesco</label>
              <v-autocomplete
                v-model="dependent.degreeKinship"
                :items="propsKinships"
                item-text="description"
                item-value="id"
                outlined
                color="textPrimary"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">CNS</label>
              <v-text-field
                v-model="dependent.cns"
                outlined
                color="textPrimary"
                :disabled="isDetail"
                @input="observerDependents()"
                v-mask="'###############'"
                :rules="dependent.cns ? [rule.cns] : []"
                validate-on-blur
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">DNV</label>
              <v-text-field
                v-model="dependent.dnv"
                v-mask="'##-########-#'"
                outlined
                color="textPrimary"
                :disabled="isDetail"
                :rules="dependent.dnv ? [rule.dnv] : []"
                validate-on-blur
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Peso(kg)</label>
              <v-text-field
                v-mask="'###'"
                type="number"
                min="1"
                v-model="dependent.weight"
                @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                @input="dependent.weight = formatter.removeInvalidNumber(dependent.weight); observerDependents()"
                @blur="validateNumbersGreaterThanOne(dependent.weight, 'fieldCheckedWeight')"
                id="fieldCheckedWeight"
                outlined
                color="textPrimary"
                :rules="controlWeight ? ['Favor informar um número válido'] : dependent.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.weight)] : []"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Altura(cm)</label>
              <v-text-field
                v-mask="'###'"
                type="number"
                min="1"
                v-model="dependent.height"
                @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                @input="dependent.height = formatter.removeInvalidNumber(dependent.height); observerDependents()"
                @blur="validateNumbersGreaterThanOne(dependent.height, 'fieldCheckedHeight')"
                id="fieldCheckedHeight"
                outlined
                color="textPrimary"
                :rules="controlHeight ? ['Favor informar um número válido'] : dependent.height ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.height)] : []"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Estado Civil</label>
              <v-autocomplete
                :items="propsMaritalStatus"
                v-model="dependent.maritalStatus"
                item-text="description"
                item-value="id"
                outlined
                color="textPrimary"
                clearable
                :disabled="isDetail"
                @input="observerDependents()"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6" md="3">
              <label class="label">Complemento de Matrícula</label>
              <v-text-field
                v-model="dependent.registrationComplement"
                maxlength="50"
                outlined
                color="textPrimary"
              />
            </v-col>
          </v-row>
          <v-col cols="12 pt-0">
            <v-row v-if="dependent.documentsList && dependent.documentsList.length > 0" class="mt-10 mb-3" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
              <v-col cols="12" md="5" v-for="(item, index) in dependent.documentsList" :key="index">
                <label class="label"> {{ item.description }}  </label>
                <v-row>
                  <v-col>
                    <v-file-input
                      v-model="item.file"
                      @change="uploadDocument(dependent, item);"
                      placeholder="Clique para adicionar"
                      accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon=""
                      color="textPrimary"
                      outlined
                      :disabled="waitingDocumentUpload"
                      :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                      :loading="waitingDocumentUpload"
                      :rules="item.file ? [rule.validateUploadFiles(item)] : []"
                      @click:clear="clearDocument(dependent, item)"
                    >
                    <template v-slot:selection="{}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            label
                            color="primary"
                            v-on="on"
                          >
                            {{ truncateText(item.file.name, 25) }}
                          </v-chip>
                        </template>
                        <span>{{ item.file.name }}</span>
                      </v-tooltip>
                    </template>
                    </v-file-input>
                  </v-col>
                  <v-col class="mb-7" align-self="center">
                    <v-btn class="text--white" :loading="item.loadingDownload" color="primary" @click="downloadDocuments(item)">
                      Baixar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import DocumentService from '@/services-http/sdi/DocumentService';

export default ({
  data: () => ({
    controlHeight: false,
    controlWeight: false,
    waitingDocumentUpload: false,
  }),

  mixins: [
    ConstantsMixin,
  ],

  props: {
    dependent: Object,
    isDetail: Boolean,
    propsGenders: Array,
    propsMaritalStatus: Array,
    propsKinships: Array,
    holder: Object,
  },

  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    async uploadDocument(dependent, item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }
        if (item.file !== null && typeof item.file !== 'undefined') {
          await this.replaceDocument(dependent, item);
          this.waitingDocumentUpload = true;
          const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
          this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
            this.waitingDocumentUpload = false;
            dependent.documents.push({
              id: response.data,
              name: item.name,
              file: item.file,
              type: item.file.type,
            });
            // eslint-disable-next-line no-param-reassign
            item.idRecentlyUploaded = response.data;
          });
          this.observerDependents();
        } else {
          await this.clearDocument(dependent, item);
        }
      }
    },
    async clearDocument(dependent, item) {
      if (dependent && item) {
        if (dependent.documents && dependent.documents.length > 0) {
          const documentIndex = dependent.documents.findIndex((document) => document.id === item.id);
          if (documentIndex >= 0) {
            dependent.documents.splice(documentIndex, 1);
            this.observerDependents();
          }
          const idDocument = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
          await this.deleteDocument(idDocument);
        }
      }
    },
    async replaceDocument(dependent, item) {
      if (dependent && item) {
        if (dependent.documents && dependent.documents.length > 0) {
          const documentIndex = dependent.documents.findIndex((document) => document.documentTypeId === item.documentTypeId);
          if (documentIndex >= 0) {
            dependent.documents.splice(documentIndex, 1);
          }
        }
      }
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    observerDependents() {
      const { dependent } = this;
      this.$emit('observerDependents', dependent);
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.documentService = new DocumentService();
  },
});
</script>
